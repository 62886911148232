import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import vermas from '../../assets/imagenes/fans-cerro/generales/anadir-2.png';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#13141f",
    color:"white"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "#13141f",
    color:"white",
    
    
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: "#13141f",
    color:"white",
    fontSize: "1.5rem"
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} style={{paddingBottom:"2.8rem"}}>
      <img src={vermas} className="cancelar" alt="ver más"/>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         CALIFICACIONES:
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
          <Typography gutterBottom>
          <h5> <b style={{color:"rgb(82 199 242)"}}>1° Puntos:</b><br/> Sumatoria de todas las calificaciones dadas a cada jugador.</h5>
          </Typography>
          <Typography gutterBottom>
          <h5><b style={{color:"rgb(82 199 242)"}}>2° Participantes:</b><br/> Cantidad de personas que calificaron a ese jugador.</h5>
          </Typography>
           <h5> <b style={{color:"rgb(82 199 242)"}}>3° Promedio:</b><br/> El número grande posicionado a la derecha es la sumatoria de todas las calificaciones dadas a cada jugador divididas la cantidad de participantes.<br/></h5>
           <h5><b style={{color:"#28a745"}}>Verde:</b><br/> Las barras que son de este color tienen un promedio de 8 a 10.<br/>
           <b style={{color:"#007bff"}}>Celeste:</b><br/>  Las barras que son de este color tienen un promedio de 5 a 7.<br/>
           <b style={{color:"#ffc107"}}>Naranja:</b><br/> Las barras que son de este color tienen un promedio de 1 a 4</h5>
           
           
          </Typography>

        </DialogContent>
      </Dialog>
    </div>
  );
}